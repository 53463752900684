@use '@angular/material' as mat;
@import 'ngx-toastr/toastr';

// it will be copied on compile with angular cli (see angular.json)
@import 'palettes_branded';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Updated to Angular Material 15:
// https://stackoverflow.com/questions/75311923/problems-after-angular-material-update-from-14-to-15

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.all-component-typographies();
//@include mat.legacy-core();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $bikecrm-frontend-primary: mat-palette($mat-indigo);
// $bikecrm-frontend-accent: mat-palette($mat-pink, A200, A100, A400);
$bikecrm-frontend-primary: mat.define-palette($md-bcrmbranded);
$bikecrm-frontend-accent: mat.define-palette($md-bcrmbranded, A200, A100, A400);
$bikecrm-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

// Interesting info on themes:
// https://blog.thoughtram.io/angular/2017/05/23/custom-themes-with-angular-material.html
// https://stackoverflow.com/questions/41440998/how-can-i-use-custom-theme-palettes-in-angular
// https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// https://medium.com/grensesnittet/dynamic-themes-in-angular-material-b6dc0c88dfd7
$bikecrm-frontend-theme: mat.define-light-theme((
  color: (
    primary: $bikecrm-frontend-primary,
    accent: $bikecrm-frontend-accent,
    warn: $bikecrm-frontend-warn,
  ),
  typography: $my-custom-typography-config,
  density: 0,
));

// https://material.angular.io/guide/theming-your-components
// https://stackoverflow.com/questions/37585309/replacing-objects-in-array
// TODO: not the recommended way: https://stackoverflow.com/questions/37585309/replacing-objects-in-array
$primary: map-get($bikecrm-frontend-theme, primary);



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bikecrm-frontend-theme);

/* You can add global styles to this file, and also import other style files */

@import "assets/css/basscss/basscss.min.css";
@import "assets/css/basscss/responsive-margin.css";


// All root components (filling the screen) should use this class, we don't put it on a div
//      surrounding the content router because sometimes we want to merge some content to the navbar
//      like a search bar
//      TODO: maybe we can apply the padding to the router and use :host selector on the components we want no padding (https://angular.io/guide/component-styles#host)
.base-paddings {
  // TODO:
  //   @extend .px2;
  //   @extend .py1;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  // space for FAB:
  padding-bottom: 100px;
}

html, body {
  height: 100%;

}

body {
  // font-family: Roboto, "Helvetica Neue", sans-serif;
  // 64px is the height of the navbar
  height: calc(100% - 64px);
  margin: 0;
}

// Custom color success
.mat-success {
  color: #176325 !important;
  background-color: #ffffff !important;
}
.mat-success[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.no-padding-dialog-container .mat-mdc-dialog-container {
  padding: 0;
}

// End FAB with text inside

// this is to fix a bug that causes the virtual keyboard to be on top of an inline edit (easy to reproduce
//      specially if the inline edit is on the bottom of the screen)
//      maybe on the future we can just listen to inline style changes and simply don't allow less than 0px on the bottom
//      the problem is that the sat popover uses negative bottom values to stay fixed to it's anchor
.app-inline-edit-panel.cdk-overlay-pane {
  bottom: 30px !important;
}

// TODO: replace with mixins: https://material.angular.io/guide/theming-your-components
.button-border-primary {
  border-style: solid !important;
  border-width: 1px !important;
  // TODO: not the recomended way: https://stackoverflow.com/questions/37585309/replacing-objects-in-array
  border-color: mat.get-color-from-palette($primary) !important;
  color: mat.get-color-from-palette($primary) !important;
  span {
    font-weight: 500 !important;
  }
}

.mat-drawer-container {
  background-color: #F6F6F6; // TODO: use mat-app-background or similar
}

.muted { opacity: .5 }

.contrast-bg {
  background-color: rgb(255, 255, 255);
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.link-action {
  cursor: pointer;
}

a.link-action {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.pointer {
  // TODO: unify with link-action
  cursor: pointer;
}

.text-light {
  font-weight: bold;
  color: #C6C6C6;
}

.link-action-colored {
  color: #4C74B9;
  // color: $bikecrm-frontend-primary;
  cursor: pointer;
}

// TODO: fix without important
.big-input {
  font-size: 20pt !important;
  font-weight: 500 !important;
  // font-weight: bold;
}

.big-input-label {
  font-size: 14pt !important;
  color: black !important;
  font-weight: 400 !important;
}


// https://stackoverflow.com/questions/3779534/how-do-i-disable-text-selection-with-css-or-javascript
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

// Remove arrows on input number firefox & webkit:
// https://stackoverflow.com/questions/23372903/hide-spinner-in-input-number-firefox-29
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.beta_indicator {
  border: 3px solid red;

  //position: absolute;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //left: 0;
  //overflow: auto;
}

app-edit-input {
  display: flex;
  @extend .w100;
}

app-inline-edit {
  display: flex;
  @extend .w100;
}

.text_right {
  text-align: right !important;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.px2i {
  padding-left:1rem !important;
  padding-right:1rem !important;
}

.m0i {
  margin: 0!important;
}

.mb0i {
  margin-bottom: 0!important;
}

.mt1i {
  margin-top: 0.5rem !important;
}

.mt3i {
  margin-top: 3rem !important;
}


.mr3i {
  margin-right: 1rem !important;
}

.contents_right {
    justify-content: right;
    flex-direction: row-reverse;
    text-align: right !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}

.chip-custom {
  border-radius: 5px;
  padding: 3px 5px;
}

.chip-custom-green {
  @extend .chip-custom;
  background-color: #DFF6DF;

}
.chip-custom-red {
  @extend .chip-custom;
  background-color: #F6DFDF;
}
.chip-custom-yellow {
  @extend .chip-custom;
  background-color: #F6F6DF;
}

// blurred text for privacy mode
.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.text-success {
  color: green;
}

.text-info {
  color: #2196F3;
}

.text-warning {
  color: #FF9800;
}

.text-danger {
  color: #F44336;
}


.mdc-text-field {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}


.filter-active {
  // This is ment to use on mat-selects if a filter is active this will show the select on primary color
  //    to make it more visible, and let the user know that there is a filter active
  mat-form-field mat-label {
    color: mat.get-color-from-palette($primary) !important;
  }

  mat-form-field select {
    color: mat.get-color-from-palette($primary) !important;
  }

  div.mdc-line-ripple::before {
    border-bottom-color: mat.get-color-from-palette($primary) !important;
  }
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

// Palette generator: http://mcg.mbitson.com/
/* For use in src/lib/core/theming/_palette.scss */
//primary #006ACC, secondary #000000, complement: #87F9BB

$md-bcrmbranded: (
  50 : #e0edf9,
  100 : #b3d2f0,
  200 : #80b5e6,
  300 : #4d97db,
  400 : #2680d4,
  500 : #006acc,
  600 : #0062c7,
  700 : #0057c0,
  800 : #004db9,
  900 : #003cad,
  A100 : #d7e2ff,
  A200 : #006ACC,
  A400 : #7197ff,
  A700 : #5885ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);


@use '@angular/material' as mat;
// https://material.angular.io/guide/typography
$my-custom-typography-config: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif'
);

body {
  font-family: "Montserrat", sans-serif;
}
